/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    code: "code",
    img: "img"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Signatu is a cloud-born Software-as-a-Service (SaaS). We run all our services\nin ", React.createElement(_components.a, {
    href: "https://aws.amazon.com"
  }, "Amazon Web Services"), " in Europe."), "\n", React.createElement(_components.p, null, "We have significant experience building large scale Internet services, and Signatu\nis built to scale with your needs to ensure that you will pay only for what you use,\nand we will be able to scale to accomodate your current and future needs."), "\n", React.createElement(_components.p, null, "Signatu provides three main endpoints - the website at ", React.createElement(_components.a, {
    href: "https://signatu.com"
  }, "https://signatu.com"), ", and the\nREST API at ", React.createElement(_components.code, null, "https://api.signatu.com"), " and the CDN at ", React.createElement(_components.code, null, "https://cdn.signatu.com"), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.img, {
    src: "/c5c1f72ff743063363df7741b472ed3b/architecture.svg",
    alt: "Architecture"
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
